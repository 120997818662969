'use client';

import { EventType } from '@/types';
import styles from './eventCard.module.scss';
import { HiUsers } from 'react-icons/hi';
import Image from 'next/image';
import { useContext } from 'react';
import { GlobalContext } from '@/context';
import Link from 'next/link';
import { PATH } from '@/data';
function EventCardComp({
  data
}: {
  data: EventType;
}) {
  const lang = useContext(GlobalContext).langText.eventCard;
  const langText = useContext(GlobalContext).langText.lang;
  return <Link href={PATH(langText).events.detail(data.slug)} target="_blank" data-sentry-element="Link" data-sentry-component="EventCardComp" data-sentry-source-file="eventCard.index.tsx">
            <div className={`${styles['card-layout']}`}>
                <div className={`${styles['image']}`}>
                    <Image src={data.main_image.file} alt={data.main_image.alt ?? ''} width={328} height={200} data-sentry-element="Image" data-sentry-source-file="eventCard.index.tsx" />
                    <span className="center-content t-p2 t-400">{data.category.title}</span>
                </div>
                <div className="mh-1">
                    <h4 className="t-h4 t-dark1 t-700 mb-2">{data.title}</h4>
                    <p className="t-dark2 t-400 t-p1">{data.place} - 2024/12/5</p>
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="t-dark3 t-400 t-p1 center-content-x gap-8">
                            <HiUsers size={18} data-sentry-element="HiUsers" data-sentry-source-file="eventCard.index.tsx" /> {lang.capacity} {data.participant}/{data.capacity}
                        </p>
                        <p className="t-500 t-primary t-p1">{data.price ? `${lang.cost} ${data.price}$` : lang.free}</p>
                    </div>
                </div>
            </div>
        </Link>;
}
export default EventCardComp;