'use client';

import { GlobalContext } from '@/context';
import { API } from '@/data';
import { RestAPI } from '@/scripts';
import { LocationType, PaginationType } from '@/types';
import { message, Result, Select, SelectProps } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { TbInfoCircleFilled } from 'react-icons/tb';
export default function LocationSelectComp(props: {
  placeholder?: string;
  initialValue?: LocationType<'detail'> | number;
  onChange?: SelectProps['onChange'];
}) {
  const [locationList, setLocationList] = useState<LocationType<'detail'>[]>();
  const lang = useContext(GlobalContext).langText.components;
  const title = (e: LocationType<'detail'>) => e?.parent ? `${e?.parent?.name} - ${e?.name}` : `${e?.name}`;
  const [selected, setSelected] = useState(props.initialValue ? typeof props.initialValue === 'number' ? {
    value: props.initialValue,
    label: props.initialValue.toString()
  } : {
    value: props.initialValue.id,
    label: title(props.initialValue)
  } : undefined);

  // search location
  const searchHandler = (value: string) => {
    if (value.length) {
      RestAPI.get<PaginationType<LocationType<'detail'>>>(API.location.index, {
        search: value,
        page_size: 10
      }).then(res => setLocationList(res.data.data)).catch(() => message.error(lang.locationSearchErr));
    } else setLocationList(undefined);
  };
  useEffect(() => {
    if (props.onChange) props.onChange(selected?.value, selected ? {
      label: selected.label,
      value: selected.value
    } : []);
  }, [selected]);
  return <Select showSearch className="w-100" placeholder={props.placeholder} optionFilterProp="label" allowClear
  // onSelect={() => form.setFields([{ name: 'location', errors: undefined }])}
  filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} value={selected} onSearch={e => searchHandler(e)} options={props.initialValue && !locationList?.length ? typeof props.initialValue === 'number' ? [{
    value: props.initialValue,
    label: props.initialValue.toString()
  }] : [props.initialValue].map(i => ({
    value: i.id,
    label: i.parent ? `${i.parent.name} - ${i.name}` : `${i.name}`
  })) : locationList?.map(i => ({
    value: i.id,
    label: i.parent ? `${i.parent?.name} - ${i.name}` : `${i.name}`
  }))} onChange={(values, options) => {
    if (!Array.isArray(options) && options && options.value) setSelected({
      value: options.value,
      label: options.label
    });else setSelected(undefined);
  }} dropdownRender={menu => <>
                    {locationList === undefined ? <Result icon={<TbInfoCircleFilled size={36} className="t-primary" />} status="info" title={lang.startType} subTitle={lang.startTypeDesc} /> : menu}
                </>} data-sentry-element="Select" data-sentry-component="LocationSelectComp" data-sentry-source-file="locationSelect.index.tsx" />;
}