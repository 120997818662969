'use client';

import styles from './primaryServiceCard.module.scss';
import { FaStar } from 'react-icons/fa';
import { LuListTodo } from 'react-icons/lu';
import { IoChatbubbleEllipsesOutline, IoShareSocialOutline } from 'react-icons/io5';
import { Button, message } from 'antd';
import Image from 'next/image';
import GalleryComp from './gallery/gallery.index';
import { CacheBackTypeEnum, OrderType } from 'types';
import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext } from 'context';
import DetailServiceModalComp from './detailModal/modal.index';
import { COLORS } from 'utilities';
import Link from 'next/link';
import { API, PATH } from 'data/index';
import { CopyToClipboard, RestAPI, useAsyncClick, useEventListener } from 'scripts';
import { __PrimaryServiceCardPropsType } from './primaryServiceCard.type';
import { SuccessOfferBuyModalComp } from 'components';
export default function PrimaryServiceCardComp({
  data,
  onBuy,
  params
}: __PrimaryServiceCardPropsType) {
  const [isOpenModal, setIsOpenModal] = useState<'useTerms' | 'comments' | undefined>(undefined);
  const [messageApi, contextHolder] = message.useMessage();
  const {
    langText,
    profile
  } = useContext(GlobalContext);
  const [orderData, setOrderData] = useState<OrderType>();
  const [printable, setPrintable] = useState(false);
  const timer = useRef<NodeJS.Timeout>();
  const [loading, setLoading] = useState(false);
  const dataSent = useRef(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const lang = langText.components;
  const stars: number[] = [1, 2, 3, 4, 5];
  const options: string[] = [];
  if (data.cash_back) options.push(`${data.cash_back} ${data.cash_back_type === CacheBackTypeEnum.constant ? '$' : '%'} ${data.cash_back_label?.length ? data.cash_back_label : lang.refund}`);
  data.extra_service?.forEach(item => {
    options.push(item);
  });
  const btnText = data.is_ordered ? lang.selected : lang.choose_to_buy + ' ' + (options.length ? options.join(' + ') : '');
  const print = () => {
    if (dataSent.current) {
      setLoading(false);
      clearInterval(timer.current);
      timer.current = undefined;
      iframeRef.current?.contentWindow?.focus();
      iframeRef.current?.contentWindow?.print();
    } else setLoading(true);
  };
  const buyHandler = () => {
    return new Promise(resolve => {
      if (!profile) {
        window.location.href = PATH(langText.lang).login(PATH(langText.lang).services.index(params));
        resolve(true);
      } else if (data.is_ordered !== 1) {
        RestAPI.post<OrderType>(API.order.init, {
          service: data.id
        }).then(res => {
          onBuy();
          setOrderData(res.data);
        }).catch(() => {
          messageApi.open({
            type: 'error',
            content: lang.initializeError
          });
        }).finally(() => resolve(true));
      } else resolve(true);
    });
  };
  const handleCopyToClipboard = (brand: string, service: string) => {
    CopyToClipboard(`${window.location.host}${PATH(langText.lang).services.detail(brand, service)}`);
    message.success(lang.copy_alert);
  };
  const submitHandlerHandler = useAsyncClick(buyHandler);
  useEffect(() => {
    if (printable && !dataSent.current && orderData) {
      iframeRef.current?.contentWindow?.postMessage(JSON.stringify({
        service: orderData?.service,
        order: orderData
      }), '*');
      dataSent.current = true;
    }
  }, [printable, data, orderData]);
  useEffect(() => {
    if (loading) timer.current = setInterval(print, 500);
  }, [loading]);
  useEventListener('message', event => {
    if (event.data === 'PRINT_IFRAME_LOADED') setPrintable(true);
  });
  return <>
            <div className={`${styles['primary-card']} d-pre-lg-none`}>
                {contextHolder}
                <SuccessOfferBuyModalComp data={data} order={orderData} open={!!orderData} onClose={() => setOrderData(undefined)} printable={printable} onPrint={print} printLoading={loading} data-sentry-element="SuccessOfferBuyModalComp" data-sentry-source-file="primaryServiceCard.index.tsx" />
                <iframe src={PATH(langText.lang).orderPrint} style={{
        width: '100%',
        height: 0,
        opacity: 0,
        border: 0
      }} ref={iframeRef} />
                <div className={`${styles['top-section']} d-flex gap-24 pb-2 mb-2`}>
                    <GalleryComp link={PATH(langText.lang).services.detail(data.brand.slug, data.slug)} images={data.gallery} data-sentry-element="GalleryComp" data-sentry-source-file="primaryServiceCard.index.tsx" />
                    <div className={`${styles['details']}`}>
                        <div className="d-flex justify-content-between mb-1">
                            <div className="gap-8">
                                {data.categories?.map(category => <Link key={category.id} target="_blank" href={PATH(langText.lang).services.index({
                category: category.slug
              })} className="t-primary t-p2 t-500">
                                        {category.title}
                                    </Link>)}
                                {data.locations?.length ? <span className="t-dark5">|</span> : null}
                                {data.locations?.map(loc => <p key={loc.id} className="t-primary t-p2 t-500">
                                        {loc.name}
                                    </p>)}
                            </div>
                            <div className="d-flex center-content-y gap-8">
                                {data.avg_star && <p className="t-primary t-400 t-p1">
                                        <FaStar size={14} /> {data.avg_star?.toFixed(1)}
                                    </p>}
                                <p className="t-dark4 t-400 t-p2">
                                    {data.total_orders ?? '0'} {lang.sale}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <a target="_blank" href={PATH(langText.lang).services.detail(data.brand.slug, data.slug)}>
                                <h4 className="t-dark1 t-h4 t-700">{data.title}</h4>
                            </a>
                            <p className="t-dark4 pointer" onClick={() => handleCopyToClipboard(data.brand.slug, data.slug)}>
                                {<IoShareSocialOutline size={18} />}
                            </p>
                        </div>
                        <div className={`${styles['desc-text']} t-400 t-p1 t-dark2 mb-2`} dangerouslySetInnerHTML={{
            __html: data.description
          }}></div>
                        <div className="d-flex justify-content-between">
                            <p onClick={() => setIsOpenModal('useTerms')} className={`${styles['terms']} t-p1 t-400 t-primary d-flex align-items-center gap-8`}>
                                <LuListTodo data-sentry-element="LuListTodo" data-sentry-source-file="primaryServiceCard.index.tsx" /> {lang.terms_of_use}
                            </p>
                            <p onClick={() => setIsOpenModal('comments')} className={`${styles['comments']} t-p1 t-400 t-primary d-flex align-items-center gap-8`}>
                                <IoChatbubbleEllipsesOutline data-sentry-element="IoChatbubbleEllipsesOutline" data-sentry-source-file="primaryServiceCard.index.tsx" /> {lang.customers_comments}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={`${styles['bottom-section']} d-flex justify-content-between`}>
                    <div className="d-flex gap-16">
                        <Link target="_blank" href={PATH(langText.lang).brand(data.brand.slug)} className="t-p1 t-500 t-dark2" data-sentry-element="Link" data-sentry-source-file="primaryServiceCard.index.tsx">
                            <Image src={data.brand.main_image ? data.brand.main_image.file : ''} width={50} height={50} alt={data.brand.main_image?.alt ?? ''} data-sentry-element="Image" data-sentry-source-file="primaryServiceCard.index.tsx" />
                        </Link>
                        <div>
                            <Link target="_blank" href={PATH(langText.lang).brand(data.brand.slug)} className="t-p1 t-500 t-dark2" data-sentry-element="Link" data-sentry-source-file="primaryServiceCard.index.tsx">
                                {data.brand.title}
                            </Link>
                            <span className="d-flex gap-8 align-items-center">
                                {data.brand.average_stars && <p className="t-primary t-p1 t-400">
                                        <FaStar color={COLORS.primary} /> {data.brand.average_stars?.toFixed(1)}
                                    </p>}
                                <p className="t-dark4 t-400 t-p2">
                                    {data.brand.total_orders} {lang.sale}
                                </p>
                            </span>
                        </div>
                    </div>
                    <Button disabled={!!data.is_ordered} {...submitHandlerHandler} data-sentry-element="Button" data-sentry-source-file="primaryServiceCard.index.tsx">
                        {btnText}
                    </Button>
                </div>
            </div>
            <div className={`${styles['primary-card-lg']} d-none d-pre-lg-block pb-3`}>
                <GalleryComp link={PATH(langText.lang).services.detail(data.brand.slug, data.slug)} images={data.gallery} data-sentry-element="GalleryComp" data-sentry-source-file="primaryServiceCard.index.tsx" />
                <div className="ph-4 mt-3">
                    <div className={`${styles['top-section-lg']} pb-2 mb-2`}>
                        <div className="d-flex justify-content-between mb-1">
                            <div className="gap-8">
                                {data.categories?.map(category => <Link key={category.id} target="_blank" href={PATH(langText.lang).services.index({
                category: category.slug
              })} className="t-primary t-p2 t-500">
                                        {category.title}
                                    </Link>)}
                                {data.locations?.length ? <span className="t-dark5">|</span> : null}
                                {data.locations?.map(loc => <p key={loc.id} className="t-primary t-p2 t-500">
                                        {loc.name}
                                    </p>)}
                            </div>
                            <p onClick={() => handleCopyToClipboard(data.brand.slug, data.slug)} className="t-p2 t-500 t-dark4 d-flex align-items-center gap-8 pointer">
                                <IoShareSocialOutline data-sentry-element="IoShareSocialOutline" data-sentry-source-file="primaryServiceCard.index.tsx" />
                            </p>
                        </div>
                        <a target="_blank" href={PATH(langText.lang).services.detail(data.brand.slug, data.slug)}>
                            <h4 className="t-dark1 t-h4 t-700 mb-1">{data.title}</h4>
                        </a>
                        <div className="d-flex align-items-center gap-8 mb-2">
                            <p className="t-p1 t-400 t-primary">
                                {stars.map(star => <FaStar key={star} size={12} color={data.avg_star?.toFixed(1) !== undefined ? star <= +data.avg_star?.toFixed(1) ? COLORS.primary : COLORS.dark5 : COLORS.dark5} />)}{' '}
                                {data.avg_star?.toFixed(1)}
                            </p>
                            <p className="t-dark4 t-400 t-p2">
                                {data.total_orders} {lang.sale}
                            </p>
                        </div>
                        <p className="t-400 t-p1 t-dark2 mb-3">{data.description}</p>
                        <div className="d-flex gap-16">
                            <Link href={PATH(langText.lang).brand(data.brand.slug)} target="_blank" data-sentry-element="Link" data-sentry-source-file="primaryServiceCard.index.tsx">
                                <Image src={data.brand.main_image?.file ?? ''} width={50} height={50} alt={data.brand.main_image?.alt ?? ''} data-sentry-element="Image" data-sentry-source-file="primaryServiceCard.index.tsx" />
                            </Link>
                            <div>
                                <Link target="_blank" href={PATH(langText.lang).brand(data.brand.slug)} className="t-p1 t-500 t-dark2" data-sentry-element="Link" data-sentry-source-file="primaryServiceCard.index.tsx">
                                    {data.brand.title}
                                </Link>
                                <span className="d-flex gap-8 align-items-center">
                                    {data.brand.average_stars && <p className="t-dark4 t-p1 t-400">
                                            <FaStar color={COLORS.primary} /> {data.brand.average_stars?.toFixed(1)}
                                        </p>}
                                    <p className="t-dark4 t-400 t-p2">
                                        {data.brand.total_orders} {lang.sale}
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles['bottom-section-lg']}`}>
                        <div className="d-flex justify-content-between mb-2">
                            <p onClick={() => setIsOpenModal('useTerms')} className={`${styles['terms-lg']} t-p1 t-400 t-primary d-flex align-items-center gap-8`}>
                                <LuListTodo data-sentry-element="LuListTodo" data-sentry-source-file="primaryServiceCard.index.tsx" /> {lang.terms_of_use}
                            </p>
                            <p onClick={() => setIsOpenModal('comments')} className={`${styles['comments-lg']} t-p1 t-400 t-primary d-flex align-items-center gap-8`}>
                                <IoChatbubbleEllipsesOutline data-sentry-element="IoChatbubbleEllipsesOutline" data-sentry-source-file="primaryServiceCard.index.tsx" /> {lang.customers_comments}
                            </p>
                        </div>
                        <Button className="w-100" disabled={!!data.is_ordered} {...submitHandlerHandler} data-sentry-element="Button" data-sentry-source-file="primaryServiceCard.index.tsx">
                            <p>{btnText}</p>
                        </Button>
                    </div>
                </div>
            </div>
            <DetailServiceModalComp data={data} title={data.title} setIsOpenModal={setIsOpenModal} type={isOpenModal} data-sentry-element="DetailServiceModalComp" data-sentry-source-file="primaryServiceCard.index.tsx" />
        </>;
}