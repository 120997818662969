export const headerLang = {
    tehranto: 'تـهرانتـو',
    otherLang: 'En',
    home: 'خانه',
    services: 'فرصت‌های خرید',
    dating: 'دوستیابی',
    events: 'ایونت و دورهمی',
    housing: 'خانه و همخانه',
    hiring: 'استخدام',
    aboutUs: 'درباره ما',
    houses: 'خانه‌ها',
    tenants: 'همخانه‌ها',
    others: 'سایر',
    blog: 'وبلاگ',
    signup: 'ثبت نام',
    login: 'ورود',
    profile: 'حساب کاربری',
};
