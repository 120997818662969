import { AppLinkComp } from '@/components';
import styles from '../header.module.scss';
import { TbChevronDown } from 'react-icons/tb';
import { usePathname } from 'next/navigation';
import { PATH } from '@/data';
import { useContext } from 'react';
import { GlobalContext } from '@/context';
import { ServiceTypeEnum } from '@/types';
import { Dropdown } from 'antd';
export default function HeaderLinksComp() {
  const {
    langText,
    profile
  } = useContext(GlobalContext);
  const lang = langText.header;
  const language = langText.lang;
  const pathname = usePathname();
  const isPage = (path: string) => pathname.split('/').join('').split('?')[0] === path.split('/').join('');
  const isLink = (item?: ServiceTypeEnum) => !(isPage(PATH(language).home()) || isPage(PATH(language).services.index()) && item === ServiceTypeEnum.offer || isPage(PATH(language).dating) && item === ServiceTypeEnum.dating && profile);
  const links = [{
    title: lang.home,
    href: PATH(language).home(),
    searchParamsSensitive: false
  }, {
    title: lang.services,
    href: PATH(language).services.index()
  }, {
    title: lang.housing,
    id: ServiceTypeEnum.housing,
    children: [{
      title: lang.houses,
      href: PATH(language).housing.houses()
    }, {
      title: lang.tenants,
      href: PATH(language).housing.roommates()
    }]
  }, {
    title: lang.hiring,
    id: ServiceTypeEnum.hiring
  }, {
    title: lang.events,
    id: ServiceTypeEnum.events
  }, {
    title: lang.dating,
    href: profile ? PATH(language).dating : undefined,
    id: profile ? undefined : ServiceTypeEnum.dating
  }, {
    title: lang.others,
    children: [{
      title: lang.blog,
      href: PATH(language).blog.index()
    }, {
      title: lang.aboutUs,
      href: PATH(language).aboutUs
    }]
  }];
  const mainLink = (link: (typeof links)[number], index?: number) => <AppLinkComp key={index} href={link.href ?? PATH(language).home(link.id)} searchParamsSensitive={link.id ? false : link.searchParamsSensitive} preventReload={link.href ? isPage(link.href) : !isLink(link.id)} className={`${link.href && isPage(link.href) ? 't-primary t-500' : ''}`} data-sentry-element="AppLinkComp" data-sentry-component="mainLink" data-sentry-source-file="links.index.tsx">
            {link.title}
        </AppLinkComp>;
  return <div className={`align-items-center d-pre-md-none ${styles['links']}`} data-sentry-component="HeaderLinksComp" data-sentry-source-file="links.index.tsx">
            {links.map((link, index) => link.children ? <Dropdown key={index} menu={{
      items: link.children.map((child, index) => ({
        label: <AppLinkComp className={`${isPage(child.href) ? 't-primary-i t-500' : ''}`} href={child.href} preventReload={isPage(child.href)} searchParamsSensitive={false}>
                                        {child.title}
                                    </AppLinkComp>,
        key: index.toString()
      }))
    }}>
                        <span className="align-items-center gap-8">
                            {link.href || link.id ? mainLink(link) : link.title}
                            <TbChevronDown />
                        </span>
                    </Dropdown> : mainLink(link, index))}
        </div>;
}