export const __HousesListLang = {
    filters: 'فیلتر ها',
    buildingType: 'نوع ساختمان',
    title: 'جست و جو خانه',
    newest: 'جدیدترین',
    most_visited: 'پربازدید ترین',
    ordering: 'مرتب سازی',
    oldest: 'قدیمی ترین',
    most_popular: 'محبوب ترین',
    result: 'نتیجه',
    shared: 'اشتراکی',
    independent: 'مستقل',
    month: 'ماه',
    search: 'جست و جو هم خونه',
    searchDesc: 'همخانه برای اشتراک خانه خود',
    search2: 'جست و جو مستاجر',
    searchDesc2: 'مستاجر برای اجاره دادن خانه خود',
    male: 'آقا',
    female: 'خانم',
    gender1: 'ساکن',
    gender2: 'به دنبال همخانه',
    gender3: '',
    normal_room: 'اتاق معمولی با سرویس و حمام مشترک',
    master_room: 'اتاق مستر با سرویس و حمام مستقل',
    small_room: 'دن یا اتاق کوچک با سرویس و حمام مشترک',
    canape: 'کاناپه یا تخت در محیط مشترک',
    independent_common: 'واحد مستقل با ورودی مشترک',
    independent_independent: 'واحد مستقل با ورودی مستقل',
    parking: 'پارکینگ',
    warehouse: 'انبار',
    elevator: 'آسانسور',
    NoPets: 'حیوانات خانگی',
    NoCigarettes: 'سیگار',
    NoLoudSounds: 'صدای بلند',
    aYear: 'یک سال',
    sixMonth: '۶ ماه',
};
