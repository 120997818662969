'use client';

import styles from './reviewCard.module.scss';
import { FaArrowTurnDown } from 'react-icons/fa6';
import Image from 'next/image';
import { ReviewType } from 'types';
import { GlobalContext } from 'context';
import React, { useContext } from 'react';
import { PATH } from 'data';
import { COLORS } from 'utilities';
import { FaRegUser, FaStar } from 'react-icons/fa';
import { Tag } from 'antd';
import { __StarCommentBadItems, __StarCommentGoodItems } from 'tehranto/panel/orderDetail/orderDetail.data';
import { DateString } from 'scripts';
function ReviewCardComp({
  data
}: {
  data: ReviewType;
}) {
  const langText = useContext(GlobalContext).langText;
  const lang = langText.reviewCard;
  const formattedDate = DateString(data.created_at, '{Y:4}/{M:2}/{D:2}', langText.lang);
  const stars: number[] = [1, 2, 3, 4, 5];
  const reviews: {
    [key: string | number]: JSX.Element;
  } = {};
  __StarCommentGoodItems(langText.panelOrderDetail).forEach(item => {
    reviews[item.id] = <Tag color="green">{item.title}</Tag>;
  });
  __StarCommentBadItems(langText.panelOrderDetail).forEach(item => {
    reviews[item.id] = <Tag color="red">{item.title}</Tag>;
  });
  return <div className={`${styles['review-card-layout']}`} data-sentry-component="ReviewCardComp" data-sentry-source-file="reviewCard.index.tsx">
            <div className={`${styles['profile']} d-flex gap-16 pb-2 pt-3 ph-4`}>
                {data.user_profile && data.user_profile.file ? <Image src={data.user_profile?.file ?? ''} alt={data.user_profile?.alt ?? ''} width={56} height={56} style={{
        borderRadius: 10
      }} /> : <div className={`${styles['not-image']} center-content`}>
                        <FaRegUser size={27} color={COLORS.dark3} />
                    </div>}
                <div className="w-100">
                    <p className="t-p1 t-700 t-dark1 mb-1">{data.user_fullname ?? ''}</p>
                    <div className={`${styles['star-date']} w-100 d-flex justify-content-between align-items-center`}>
                        {data.rate !== undefined && data.rate !== null ? <span className="d-flex align-items-center">
                                {stars.map(star => <FaStar key={star} size={12} color={data.rate ? star <= +data.rate ? COLORS.primary : COLORS.dark5 : COLORS.dark5} />)}{' '}
                                <p className="t-p1 t-400 t-primary">{data.rate}</p>
                            </span> : <p className="t-p2 t-400 t-dark4">{lang.noReview}</p>}
                        <p className="t-p2 t-400 t-dark4">{formattedDate}</p>
                    </div>
                </div>
            </div>
            <div className={`${styles['comment']} ph-4 pb-3`}>
                <p className="t-400 t-p1 t-dark2">{data.comment}</p>
                <div className="mt-1 mb-2">
                    {data.reviews?.map((item, index) => <React.Fragment key={index}>{reviews[item]}</React.Fragment>)}
                </div>
                <a target="_blank" href={PATH(langText.lang).services.detail(data.service.brand.slug, data.service.slug)} className="t-500 t-p1 t-primary center-content-y gap-8">
                    <FaArrowTurnDown size={20} data-sentry-element="FaArrowTurnDown" data-sentry-source-file="reviewCard.index.tsx" />
                    {lang.service} {data.service.title}
                </a>
            </div>
        </div>;
}
export default ReviewCardComp;