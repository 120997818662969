import React, { useContext, useState } from 'react';
import styles from './header.module.scss';
import { GlobalContext } from 'context';
import { LanguageEnum } from 'types';
import { usePathname } from 'next/navigation';
import { Button } from 'antd';
import { useEventListener } from 'scripts';
import { TbWorld, TbMenu2, TbUserCircle } from 'react-icons/tb';
import DrawerComp from './drawer/drawer.index';
import logoFA from 'assets/images/logo-typo-fa.svg';
import logoEN from 'assets/images/logo-typo-en.svg';
import Image from 'next/image';
import { PATH } from 'data';
import { AppLinkComp } from '..';
import HeaderLinksComp from './links/links.index';
export default function HeaderComp(props: {
  hasSlug?: boolean;
}) {
  const {
    langText,
    profile
  } = useContext(GlobalContext);
  const lang = langText.header;
  const language = langText.lang;
  const pathname = usePathname();
  const [collapse, setCollapse] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const nextLang = () => {
    const nextL = language === LanguageEnum.fa ? LanguageEnum.en : LanguageEnum.fa;
    return {
      fontFamily: nextL === LanguageEnum.fa ? 'AnjomanMax' : 'AnjomanMax',
      link: `\\${nextL}` + (props.hasSlug ? '' : pathname.slice(3))
    };
  };
  useEventListener('scroll', () => {
    if (window.scrollY > 0) setCollapse(true);else setCollapse(false);
  });
  return <header className={`${styles['tehranto-header']} ${collapse ? styles['collapsed'] : ''}`} data-sentry-component="HeaderComp" data-sentry-source-file="header.index.tsx">
            <DrawerComp open={open} onClose={() => setOpen(false)} data-sentry-element="DrawerComp" data-sentry-source-file="header.index.tsx" />
            <div className="h-100 main-content gap-16 justify-content-between align-items-center">
                <AppLinkComp href={PATH(language).home()} searchParamsSensitive={false} data-sentry-element="AppLinkComp" data-sentry-source-file="header.index.tsx">
                    <h1 className="t-h3 t-primary align-items-center gap-8">
                        <Image src={language === LanguageEnum.fa ? logoFA : logoEN} alt="Tehranto" height={40} data-sentry-element="Image" data-sentry-source-file="header.index.tsx" />
                        {/* {lang.tehranto} */}
                    </h1>
                </AppLinkComp>
                <HeaderLinksComp data-sentry-element="HeaderLinksComp" data-sentry-source-file="header.index.tsx" />
                <div className={`align-items-center d-pre-md-none ${styles['tools']}`}>
                    <a href={nextLang().link} className="gap-8 t-400">
                        <TbWorld size={20} data-sentry-element="TbWorld" data-sentry-source-file="header.index.tsx" />
                        <span style={{
            fontFamily: nextLang().fontFamily
          }}>{lang.otherLang}</span>
                    </a>
                    <div className={`gap-8`}>
                        {profile ? <AppLinkComp href={PATH(language).panel.index}>
                                <Button size="small" type="primary" className="center-content-y d-flex-i" icon={<TbUserCircle size={20} />}>
                                    {profile.first_name ? profile.first_name : lang.profile}
                                </Button>
                            </AppLinkComp> : <AppLinkComp href={PATH(language).login()}>
                                <Button className="p-0-i" size="small">
                                    <span className="ph-3">
                                        {lang.login}/{lang.signup}
                                    </span>
                                </Button>
                            </AppLinkComp>}
                    </div>
                </div>
                <Button type="primary" className="d-md-none-i" size="small" style={{
        width: 45
      }} onClick={() => setOpen(true)} aria-label="menu bar" data-sentry-element="Button" data-sentry-source-file="header.index.tsx">
                    <TbMenu2 size={20} data-sentry-element="TbMenu2" data-sentry-source-file="header.index.tsx" />
                </Button>
            </div>
        </header>;
}