export const __HomeAdsLang = {
    shared: 'اشتراکی',
    independent: 'مستقل',
    buildingType: 'نوع ساختمان',
    month: 'ماه',
    title: 'آگهی های خانه',
    newAds: 'افزودن آگهی جدید',
    enable: 'فعال',
    disable: 'غیر فعال',
    empty: 'آگهی‌ای وجود ندارد',
    emptyDesc: 'شما تا به حال آگهی خانه‌ای منتشر نکرده‌اید. جهت ساخت اولین مورد، نوع آگهی خود را انتخاب کنید.',
    independent_house: 'خانه مستقل',
    shared_house: 'خانه اشتراکی',
    addIndependentHouse: 'افزودن خانه مستقل',
    addSharedHouse: 'افزودن خانه اشتراکی',
};
