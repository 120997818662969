import { RiDiscountPercentFill, RiHeartsFill } from 'react-icons/ri';
import { __ServiceNavigationLang } from './serviceNavigation.lang';
import { HiUserGroup } from 'react-icons/hi';
import { GoHomeFill } from 'react-icons/go';
import { ServiceTypeEnum } from '@/types';
import { FaUserTie } from 'react-icons/fa6';

export const __ServiceNavigation = (lang: typeof __ServiceNavigationLang) => {
    return [
        {
            id: ServiceTypeEnum.offer,
            title: lang.buy,
            icon: RiDiscountPercentFill,
        },
        {
            id: ServiceTypeEnum.housing,
            title: lang.home,
            icon: GoHomeFill,
        },
        {
            id: ServiceTypeEnum.hiring,
            title: lang.hiring,
            icon: FaUserTie,
        },
        {
            id: ServiceTypeEnum.events,
            title: lang.events,
            icon: HiUserGroup,
        },
        {
            id: ServiceTypeEnum.dating,
            title: lang.friend,
            icon: RiHeartsFill,
        },
    ];
};
