export const __HomeAdDetailLang = {
    title1: 'آگهی خانه اشتراکی',
    title2: 'آگهی خانه مستقل',
    disabled: 'غیر فعال سازی',
    activation: 'فعال سازی',
    editInfo: 'ویرایش اطلاعات',
    lookingFor: 'همچنان به دنبال همخانه برای خانه خود میگردم',
    info: 'اطلاعات شخصی',
    telID: 'آیدی اکانت تلگرام شما:',
    yearBirth: 'سال تولد:',
    gender: 'جنسیت:',
    dGender: 'جنسیت مورد نظر برای همخونه:',
    bio: 'بیو:',
    timing: 'زمان بندی',
    stayTime: 'مدت سکونت:',
    roomInfo: 'مشخصات واحد مورد نظر ',
    buildingType: 'نوع ساختمان:',
    stayType: 'نوع اقامت:',
    place: 'محله:',
    address: 'محله و آدرس',
    budget: 'بودجه:',
    possibilities: 'امکانات:',
    limitations: 'محدودیت ها:',
    other: 'سایر شرایط مورد نظر شما:',
    female: 'زن',
    male: 'مرد',
    map: 'مشاهده لوکیشن در google map',
    images: 'تصاویر خانه',
    err: 'عملیات با خطا مواجه شد. لطفا بعدا تلاش کنید.',
    delete: 'حذف',
    confirmDelete: 'آیا اطمینان دارید این مورد حذف شود؟',
    cancel: 'انصراف',
};
