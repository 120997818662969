'use client';

import { GlobalContext } from '@/context';
import { COLORS } from '@/utilities/index';
import { useContext } from 'react';
import { __ServiceNavigation } from './serviceNavigation.data';
import styles from './serviceNavigation.module.scss';
import { AppLinkComp } from '@/components';
import { usePathname /* , useRouter */ } from 'next/navigation';
import { PATH } from '@/data';
// import { RiDiscountPercentFill } from 'react-icons/ri';
import { ServiceTypeEnum } from '@/types';
// import { nextBrowser } from '@/scripts';

export function __ServiceNavigationComp({
  activeIndex,
  setServiceActive,
  className,
  setActiveIndex
}: {
  activeIndex?: ServiceTypeEnum;
  setActiveIndex?: (e: ServiceTypeEnum) => void;
  setServiceActive?: (e: boolean) => void;
  className?: string;
}) {
  const {
    langText,
    profile
  } = useContext(GlobalContext);
  const lang = langText.serviceNavigation;
  const activeService = activeIndex ?? ServiceTypeEnum.offer;
  const pathname = usePathname();
  const isPage = (path: string) => pathname.split('/').join('').split('?')[0] === path.split('/').join('');
  const isHome = isPage(PATH(langText.lang).home());
  const isServicePage = isPage(PATH(langText.lang).services.index());
  const isDating = isPage(PATH(langText.lang).dating);
  const isLink = (item: ServiceTypeEnum) => !(isHome || isServicePage && item === ServiceTypeEnum.offer || profile && isDating && item === ServiceTypeEnum.dating);
  const clickHandler = (item: ServiceTypeEnum) => {
    if (setActiveIndex) setActiveIndex(item);
    if (setServiceActive) setServiceActive(false);
  };
  return <div className={`${styles['bottom-navigation-layout']} d-md-none ${className}`} data-sentry-component="__ServiceNavigationComp" data-sentry-source-file="serviceNavigation.index.tsx">
            <div className={`${styles['container']}`}>
                {__ServiceNavigation(lang).map(item => <AppLinkComp href={PATH(langText.lang).home(item.id)} key={item.id} className={styles['item']} searchParamsSensitive={false} preventReload={!isLink(item.id)} onClick={() => clickHandler(item.id)}>
                        <item.icon size={28} color={activeService === item.id ? COLORS.primary : COLORS.dark3} />
                        <p className={`${activeService === item.id ? 't-primary' : 't-dark2'} t-p3 text-center`}>
                            {item.title}
                        </p>
                    </AppLinkComp>)}
            </div>
        </div>;
}