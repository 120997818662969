export const __LandingLang2 = {
    bestOpp: 'بهترین فرصت های',
    title1: 'خرید و خدمات با تخفیف و آفرهای ویژه منطبق با نیاز شما',
    title2: 'آشنایی و دوستی منطبق با شخصیت و علاقه شما',
    title3: 'اقامت و پیدا کردن همخانه منطبق با بودجه شما',
    title4: 'ایونت و دورهمی های جذاب منطبق با سلیقه شما',
    title5: 'استخدام و کاریابی مطابق با خواسته‌ها و توانمندی‌های شما',
    inToronto: 'در تورنتو',
    buy: 'خرید و خدمات',
    buy2: 'فرصت‌های خرید',
    see: 'مشاهده',
    seeAll: 'مشاهده همه',
    seeAllService: 'مشاهده همه خدمت ها',
    personalizeDating: 'دوستیابی مطابق با تیپ شخصیتی',
    seeMore: 'مشاهده بیشتر...',
    events: 'ایونت و دورهمی',
    home: 'خانه و همخانه',
    friend: 'دوستیابی',
    hiring: 'استخدام',
    houseSearch: 'جستجوی خانه',
    tenantSearch: 'جستجوی همخانه',
    moreHouseSearch: 'مشاهده خانه‌های بیشتر',
    moreTenantSearch: 'مشاهده همخانه‌های بیشتر',
    serviceTitle: 'سایر خدمات تهرانتو',
    service1: 'کمک به رشد و توسعه کسب و کارها',
    service2: 'خدمات ویژه به تازه واردین',
    service3: 'خدمات در ایران برای اقوام و آشنایان مقیم کانادا',
    service4: 'ویزا و مهاجرت',
    desc1: 'توسعه محصول، طراحی وبسایت، اپلیکیشن، تولید محتوای شبکه های ...',
    desc2: 'ترانسفر و اقامت کوتاه مدت. انتقال پول با کمترین کارمزد و ...',
    desc3: 'هماهنگی برگزاری جشن و مراسم. هماهنگی خرید و امور خدماتی و...',
    desc4: 'ورک پرمیت ایرانیان. روش‌های اقامت دائم استانی. روش‌های اکسپرس و...',
    yearsOld: 'ساله',
    errorMessage: 'درخواست با خطا مواجه شد.',
    offersHint: {
        desc: 'تهرانتو کلاب با ارائه بهترین پیشنهادهای قیمتی و خدمات به جامعه فارسی‌زبان و ایرانیان مقیم تورنتو و کانادا، تلاش می‌کند تا با بازگشت قسمتی از مبلغ خرید و جمع‌آوری نظرات کاربران، هم به شما در یافتن بهترین خدمات و هم به رونق بیشتر بیزینس‌ها کمک کند. به این ترتیب، در آینده شاهد خلق خدمات شایسته‌تری خواهیم بود.',
        steps: [
            {
                title: 'بررسی',
                desc: 'ابتدا خدمت یا سرویس مورد نظر خود را از دسته‌بندی‌های موجود انتخاب کنید.',
            },
            {
                title: 'انتخاب',
                desc: 'با بررسی پیشنهادها و نظرات دیگر کاربران، بهترین گزینه را انتخاب نمایید.',
            },
            {
                title: 'خرید',
                desc: 'مستقیماً با شرکت یا بیزینس مورد نظر ارتباط بگیرید و خرید خود را نهایی کنید.',
            },
            {
                title: 'ثبت بازخورد',
                desc: 'پس از خرید، با مراجعه دوباره به سایت تهرانتو، تجربه خود را ثبت کنید',
            },
            {
                title: 'دریافت وجه',
                desc: 'بازگشت وجه خرید یا هر پیشنهاد ویژه‌ای که اعلام شده را از تهرانتو دریافت کنید.',
            },
        ],
    },
    datingHint: {
        desc: 'یافتن یک دوست یا پارتنر ایده‌آل می‌تواند چالش‌برانگیز باشد، به‌ویژه زمانی که در کشور خود نباشید. در تهرانتو کلاب، محیطی امن و کارآمد را فراهم کرده‌ایم تا به شما کمک کنیم بهترین فرصت‌های آشنایی و دوستی منطبق با شخصیت خود را در بین جامعه فارسی‌زبان و ایرانیان مقیم تورنتو و کانادا پیدا کنید.',
        steps: [
            {
                title: 'تست شخصیت شناسی',
                desc: 'به 24 سوال شخصیت‌شناسی ai پاسخ دهید و تایپ شخصیت خود را بشناسید.',
            },
            {
                title: 'تیپ های شخصیتی سازگار',
                desc: 'ببینید کدام تیپ‌های شخصیتی با شما سازگاری بیشتری دارند.',
            },
            {
                title: 'جستجو در بین کاربران',
                desc: 'با فعال کردن دیده‌شدن، افراد سازگار را پیدا کنید.',
            },
            {
                title: '7 شانس آشنایی در تلگرام',
                desc: '7 فرصت آشنایی برای ارتباط مستقیم با آی‌دی تلگرام با در نظر گرفتن همه جوانب احتیاط',
            },
            {
                title: 'موفقیت یا انتظار برای شانس بیشتر',
                desc: 'در صورت عدم موفقیت در گزینه ها، منتظر شانس‌های آشنایی بیشتر باشید.',
            },
        ],
    },
    housingHint:
        'پیدا کردن خانه و هم‌خانه یکی از چالش‌های اصلی زندگی در کانادا است. این روزها، به دلیل هزینه‌های بالای زندگی، بسیاری از افراد ترجیح می‌دهند به‌جای زندگی در یک خانه مستقل، در یک اتاق مستقل با امکانات مشترک مانند آشپزخانه، حریم خصوصی خود را حفظ کنند و هزینه‌ها را کاهش دهند. در تهرانتو کلاب، چه به دنبال خانه یا هم‌خانه باشید و چه بخواهید خانه‌ای را به‌طور کامل یا بخشی از آن را اجاره دهید، می‌توانید از طریق پلتفرم طراحی‌شده ما، گزینه‌ی مناسب را بر اساس نیازهای خود پیدا کنید.',
    eventsHint:
        'تهرانتو کلاب یک پلتفرم اجتماعی است که به شما امکان می‌دهد شبکه‌ای از افرادی که با شما هم‌سلیقه و هم‌فکر هستند را پیدا کنید و فرصت معاشرت با افراد جدید و نزدیک به شخصیت و سلیقه خود را بیابید. همچنین، شما می‌توانید خودتان برگزارکننده این جمع‌ها و ایونت‌ها باشید و بر اساس تجربه و ایده‌های خود تصمیم بگیرید که گروهتان چه زمانی، کجا، چند وقت یک‌بار و حول چه موضوعی گرد هم آیند.',
};
