import { GlobalContext } from 'context';
import { Empty, Modal, Pagination, Spin, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import styles from './modal.module.scss';
import { __ModalPropsType } from './modal.type';
import { ReviewCardComp } from 'components';
import { ReviewType, PaginationType } from 'types';
import { RestAPI } from 'scripts';
import { API } from 'data';
function ServiceCardModalComp({
  data,
  setIsOpenModal,
  title,
  type
}: __ModalPropsType) {
  const lang = useContext(GlobalContext).langText.components;
  const langText = useContext(GlobalContext).langText;
  const [reviewList, setReviewList] = useState<{
    data: PaginationType<ReviewType>;
    page: number;
  }>();
  const [messageApi, contextHolder] = message.useMessage();
  const getComments = (page: number) => {
    if (type === 'comments') {
      setReviewList(undefined);
      RestAPI.get<PaginationType<ReviewType>>(API.order.public, {
        lang: langText.lang,
        page_size: 4,
        page,
        service__slug: data.slug,
        ordering: '-created_at'
      }).then(res => setReviewList({
        data: res.data,
        page
      })).catch(() => {
        messageApi.open({
          type: 'error',
          content: lang.errorMessage
        });
      });
    }
  };
  useEffect(() => getComments(1), [type]);
  return <Modal title={title} open={type !== undefined} onOk={() => setIsOpenModal(undefined)} onCancel={() => setIsOpenModal(undefined)} cancelButtonProps={{
    type: 'primary',
    className: 'ph-3-i'
  }} okButtonProps={{
    type: 'default',
    className: 'ph-3-i'
  }} okText={lang.ok} cancelText={lang.close} width={800} data-sentry-element="Modal" data-sentry-component="ServiceCardModalComp" data-sentry-source-file="modal.index.tsx">
            {type === 'useTerms' && <>
                    <div className={`${styles['text-layout']} mb-2`}>
                        <h4 className="t-h5 t-700 t-dark1 mb-2">{lang.description}</h4>
                        <div dangerouslySetInnerHTML={{
          __html: data.description
        }} />
                    </div>
                    <div className={`${styles['text-layout']} mt-4 mb-2`}>
                        <h4 className="t-h5 t-700 t-dark1 mb-2">{lang.useCondition}</h4>
                        <div dangerouslySetInnerHTML={{
          __html: data.use_terms
        }}></div>
                    </div>
                </>}
            {type === 'comments' && <div className={`${styles['comments']}`}>
                    {!reviewList ? <div className={`w-100 pv-6 center-content ${styles['loading']}`}>
                            <Spin />
                        </div> : reviewList.data.count === 0 ? <Empty description={lang.noComment} /> : <div className={`flex-column gap-16`}>
                            {reviewList.data.data.map((item, index) => <ReviewCardComp key={index} data={item} />)}
                        </div>}
                    <div className={`${styles['pagination-review-layout']} ${(reviewList?.data.count ?? 0) > 4 ? 'd-flex' : 'd-none'} center-content`}>
                        <div className={`${styles['pagination-review']} d-flex center-content`}>
                            <Pagination size="small" hideOnSinglePage current={reviewList ? reviewList.page : 1} pageSize={4} total={reviewList?.data.count} showSizeChanger={false} onChange={getComments} />
                        </div>
                    </div>
                    {contextHolder}
                </div>}
        </Modal>;
}
export default ServiceCardModalComp;