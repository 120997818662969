'use client';

import styles from './serviceFilter.module.scss';
import { FaFilter } from 'react-icons/fa6';
import { GoTrash } from 'react-icons/go';
import { CiSearch } from 'react-icons/ci';
import { Input, Slider, Tree, Checkbox, Drawer, AutoComplete, Spin } from 'antd';
import { IoIosArrowBack } from 'react-icons/io';
import { Key, useContext, useEffect, useRef, useState } from 'react';
import { LanguageEnum, CategoryType, PaginationType } from '@/types';
import { GlobalContext } from '@/context';
import { __ServiceFilterPropsType, RenderedCategory } from './serviceFilter.type';
import { DefaultOptionType } from 'antd/es/select';
import { RestAPI } from '@/scripts';
import { API } from '@/data';
import { __LocationType } from '@/types/tehranto';
export default function ServiceFilterComp({
  category,
  brands,
  isOpen,
  setIsOpen,
  filterValues,
  setFilterValues
}: __ServiceFilterPropsType) {
  const [searchBrand, setSearchBrand] = useState<string>('');
  const [searchService, setSearchService] = useState<string>('');
  const [locationSearch, setLocationSearch] = useState('');
  const lang = useContext(GlobalContext).langText.serviceFilter;
  const langText = useContext(GlobalContext).langText;
  const [locations, setLocations] = useState<DefaultOptionType[]>();
  const timeout = useRef<NodeJS.Timeout>();
  const [sliderRange, setSliderRange] = useState<[number, number]>([filterValues.starLTR ?? 1, filterValues.starGTE ?? 5]);
  const renderSubCategory = (parent?: CategoryType): RenderedCategory[] => {
    if (!category.filter(item => item.parent === parent?.slug).length) null;
    return category.filter(i => i.parent?.slug === parent?.slug).sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0)).map(item => ({
      title: <span className={`${langText.lang === LanguageEnum.fa ? item?.parent?.parent ? 'mr-7-i' : 'mr-3-i' : item?.parent?.parent ? 'ml-7-i' : 'ml-3-i'} ${styles['nowrap']}`}>
                        {item.title}
                    </span>,
      key: item.slug,
      children: renderSubCategory(item)
    }));
  };
  const filteredBrands = brands?.map(item => ({
    label: item.title,
    value: item.slug
  })).filter(brand => brand.label.toLowerCase().includes(searchBrand.toLowerCase()));
  const searchHandler = () => {
    if (searchService) setFilterValues({
      ...filterValues,
      search: searchService,
      page: '1'
    });
  };
  const sliderHandler = (range: number[]) => {
    const newStarLTR = +range[1];
    const newStarGTE = +range[0];
    if (newStarLTR !== filterValues.starLTR || newStarGTE !== filterValues.starGTE) {
      setFilterValues({
        ...filterValues,
        starLTR: newStarLTR,
        starGTE: newStarGTE,
        page: '1'
      });
    }
  };
  const categoryHandler = (selectedKeys: Key[]) => {
    setFilterValues({
      ...filterValues,
      category: selectedKeys[0] as string,
      page: '1'
    });
  };
  // const serviceTypeHandler = (serviceType: string) => {
  //     setFilterValues({ ...filterValues, serviceType });
  // };
  const brandsHandler = (brand: string[]) => {
    setFilterValues({
      ...filterValues,
      brand,
      page: '1'
    });
  };
  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    if (locationSearch.length) {
      setLocations(undefined);
      timeout.current = setTimeout(() => {
        RestAPI.get<PaginationType<__LocationType<'detail'>>>(API.location.index, {
          search: locationSearch,
          page: 1,
          page_size: 30
        }).then(res => {
          const opts = res.data.data.map(i => ({
            label: i.parent ? `${i.parent?.name} | ${i.name}` : i.name,
            value: i.name,
            title: i.id.toString()
          }));
          setLocations([...opts]);
        });
      }, 600);
    } else setLocations([]);
  }, [locationSearch]);
  const filter = <>
            <div className="d-flex justify-content-between mb-3">
                <h4 className="t-700 t-h4 t-dark1 d-flex center-content gap-8">
                    <FaFilter size={18} /> {lang.filters}
                </h4>
                <p onClick={() => {
        setFilterValues({});
        setSearchService('');
        setSliderRange([1, 5]);
      }} className="t-400 t-p1 t-dark3 d-flex center-content gap-8 pointer">
                    <GoTrash size={18} /> {lang.delete}
                </p>
            </div>
            <Input.Search className="mb-2-i" placeholder={lang.search_services} onSearch={searchHandler} onChange={e => setSearchService(e.target.value)} value={searchService ?? filterValues?.search ?? ''} />
            <p className="t-400 t-dark2 t-p2 mb-4">{lang.service_points}</p>
            <Slider range={{
      draggableTrack: true
    }} defaultValue={[filterValues?.starLTR ?? 1, filterValues?.starGTE ?? 5]} value={sliderRange} max={5} min={1} step={0.1} className={`${styles['slider']} mb-4-i`} onChange={(value: any) => setSliderRange(value as [number, number])} onChangeComplete={sliderHandler} />
            <p className="t-400 t-dark2 t-p2 mb-1">{lang.service_categories}</p>
            <Tree className={`${styles['parent-node']}`} treeData={category.filter(i => !i.parent).sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0)).map(item => ({
      title: <span className={!item.parent ? '' : ''}>{item.title}</span>,
      key: item.slug,
      children: renderSubCategory(item)
    }))} switcherIcon={<IoIosArrowBack size={16} />} onSelect={categoryHandler} selectedKeys={[filterValues?.category ?? '']} defaultExpandedKeys={[filterValues?.category ?? '']} />
            {/* <p className="t-400 t-dark2 t-p2 mv-2">{lang.service_type}</p>
             <Select
                placeholder={lang.select}
                className={`${styles['select-box']}`}
                options={[
                    { value: 'cache_back', label: lang.cache_back },
                    { value: 'extra_service', label: lang.extra_service },
                    { value: 'both', label: lang.both },
                ]}
                // onChange={serviceTypeHandler}
                value={filerValues?.serviceType ?? null}
             /> */}
            {brands ? <>
                    <p className="t-400 t-dark2 t-p2 mb-1 mt-2">{lang.locationLabel}</p>
                    <AutoComplete className="w-100 tb-2" options={locations} searchValue={locationSearch} onSelect={(_, e) => {
        setFilterValues({
          ...filterValues,
          location: e.title,
          page: '1'
        });
      }} onSearch={text => {
        setLocationSearch(text);
      }} placeholder={lang.locationPH} defaultActiveFirstOption={false} notFoundContent={locations === undefined && locationSearch.length ? <Spin size="small"></Spin> : undefined} />
                </> : null}
            {brands ? <div className={`${styles['checkbox-list']}`}>
                    <p className="t-400 t-dark2 t-p2 mt-2 mb-1">{lang.brands}</p>
                    <Input placeholder={lang.search_brands} suffix={<CiSearch size={18} />} className="mb-2" value={searchBrand} onChange={e => setSearchBrand(e.target.value)} />
                    <div className="flex-column pb-2">
                        <Checkbox.Group value={filterValues?.brand ?? []} className="mb-1-i" name="brand" onChange={brandsHandler} options={filteredBrands} />
                    </div>
                </div> : null}
        </>;
  return <>
            <Drawer placement={langText.lang === LanguageEnum.en ? 'left' : 'right'} open={isOpen} onClose={() => setIsOpen(false)} width="100%" className={`${styles['header-drawer']} d-none-i d-pre-lg-block-i`} data-sentry-element="Drawer" data-sentry-source-file="serviceFilter.index.tsx">
                <div className={`${styles['service-filter-layout']}`}>{filter}</div>
            </Drawer>
            <div className={`${styles['service-filter-layout']} d-pre-lg-none`}>{filter}</div>
        </>;
}