import { hexToRgb } from 'scripts';
import { COLORS } from 'utilities';
import { ThemeConfig, theme } from 'antd';
import { LanguageEnum } from '@/types';

export const __theme: (l: LanguageEnum) => ThemeConfig = (lang) => ({
    algorithm: theme.defaultAlgorithm,
    components: {
        Button: {
            fontSize: 16,
            borderRadius: 10,
            controlHeight: 50,
            borderRadiusSM: 10,
            controlHeightSM: 45,
            controlHeightXS: 40,
            controlHeightLG: 66,
            paddingContentHorizontal: 10,
        },
        Input: {
            fontSize: 16,
            borderRadius: 10,
            controlHeight: 50,
            paddingInline: 20,
            colorText: COLORS.dark2,
            colorTextPlaceholder: COLORS.dark4,
            colorBorder: COLORS.fieldBG,
            colorBgContainer: COLORS.fieldBG,
            colorBgContainerDisabled: hexToRgb(COLORS.fieldBG, 0.5),
            hoverBorderColor: COLORS.fieldBG,
            activeBorderColor: COLORS.fieldBG,
            boxShadow: 'transparent',
            activeShadow: 'transparent',
            colorErrorBorder: COLORS.red,
        },
        InputNumber: {
            fontSize: 16,
            borderRadius: 10,
            controlHeight: 50,
            paddingInline: 20,
            colorText: COLORS.dark2,
            colorTextPlaceholder: COLORS.dark4,
            colorBorder: COLORS.fieldBG,
            colorBgContainer: COLORS.fieldBG,
            colorBgContainerDisabled: hexToRgb(COLORS.fieldBG, 0.5),
            hoverBorderColor: COLORS.fieldBG,
            activeBorderColor: COLORS.fieldBG,
            boxShadow: 'transparent',
            activeShadow: 'transparent',
            colorErrorBorder: COLORS.red,
        },
        Select: {
            fontSize: 16,
            borderRadius: 10,
            controlHeight: 50,
            colorText: COLORS.dark2,
            colorTextPlaceholder: COLORS.dark4,
            colorBorder: COLORS.fieldBG,
            colorBgContainer: COLORS.fieldBG,
            boxShadow: 'transparent',
            activeShadow: 'transparent',
            colorBgContainerDisabled: hexToRgb(COLORS.fieldBG, 0.5),
            hoverBorderColor: COLORS.fieldBG,
            activeBorderColor: COLORS.fieldBG,
            colorErrorBorder: COLORS.red,
        },
        Form: {
            labelColor: COLORS.dark2,
            labelFontSize: 14,
        },
        Pagination: {
            controlHeight: 56,
            controlHeightSM: 36,
            borderRadius: 10,
            fontSize: 16,
            itemActiveBg: COLORS.primary,
            colorPrimary: COLORS.fieldBG,
            colorPrimaryHover: COLORS.fieldBG,
            borderRadiusSM: 10,
        },
        DatePicker: {
            colorErrorBorder: COLORS.red,
        },
    },
    token: {
        colorPrimary: COLORS.primary,
        fontFamily: lang === LanguageEnum.fa ? 'AnjomanMax' : 'AnjomanMax',
    },
});
